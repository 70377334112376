<template>
  <div class="flex justify-between items-center">
    <app-heading>
      {{ t('models.payment', 2) }}
    </app-heading>
  </div>

  <template v-if="!paymentsLoading">
    <app-card-list
      v-if="payments.length > 0"
      :list="payments"
      :component="PaymentCard"
    />

    <p
      v-else
      class="mt-2 text-center"
    >
      {{ t('payments.index.no_results') }}
    </p>

    <template v-if="lastPage && page < lastPage">
      <a
        v-if="nextPageFetching === false"
        href="#"
        class="block text-center app-link w-full"
        @click.prevent="nextPage"
      >
        {{ t('common.actions.load_more') }}
      </a>
    </template>

    <div
      v-if="nextPageFetching"
      class="text-center w-full"
    >
      <app-spinner
        class="text-theme-500"
        size="lg"
      />
    </div>
  </template>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { fetchPayments as fetchApiPayments } from '@shared/http/api'
import AppHeading from '@app/components/ui/AppHeading.vue'
import AppCardList from '@shared/components/ui/card/AppCardList.vue'
import AppSpinner from '@shared/components/ui/AppSpinner.vue'
import PaymentCard from '@app/components/resources/payment/PaymentCard.vue'

const { t } = useI18n()

// ---------- PAYMENTS ----------

const payments = ref([])
const paymentsLoading = ref(true)

function fetchPayments(nextPage = false) {
  if (nextPage === false) {
    paymentsLoading.value = true
  }

  nextPageFetching.value = nextPage

  const params = {
    'page': page.value,
    'mode': 'payer',
  }

  fetchApiPayments(params)
    .then((response) => {
      const oldPayments = payments.value
      const newPayments = response.data.data?.map((payment) => ({
        resource: payment,
      })) ?? []

      if (nextPage) {
        // Add new results after the old ones
        payments.value = [...oldPayments, ...newPayments]
      } else {
        // Replace the old results with the new ones
        payments.value = newPayments
      }

      lastPage.value = response.data.meta?.last_page
    })
    .finally(() => {
      nextPageFetching.value = false
      paymentsLoading.value = false
    })
}

// ---------- PAGINATION ----------

const nextPageFetching = ref(false)
const lastPage = ref(null)
const page = ref(1)

function nextPage() {
  page.value += 1
  fetchPayments(true)
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPayments()
})
</script>
