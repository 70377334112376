import i18n from '@shared/i18n/vue-i18n'
import PaymentPayableReservation from '@extranet/components/resources/payment/payable/PaymentPayableReservation.vue'

/**
 * Display an amount with its currency symbol
 *
 * @param {number} amount
 * @param {string} currency
 * @return {string}
 */
export const getAmountWithCurrency = (amount, currency) => (
  new Intl.NumberFormat(
    i18n.global.locale,
    { style: 'currency', currency },
  )
    .format(amount)
)

/**
 * Retrieve element related to this payment
 *
 * @param payment
 * @returns {null|{component: *, props: {payableId}}}
 */
export const getPayable = (payment) => {
  let payableComponent = null

  // Not a valid payable
  if (!payment.resource.attributes.payable.type) {
    return null
  }

  // Components depends on payable type
  switch (payment.resource.attributes.payable.type) {
    case 'App\\Models\\Reservation':
      payableComponent = PaymentPayableReservation
      break
    default:
      return null
  }

  return {
    component: payableComponent,
    props: {
      payableId: payment.resource.attributes.payable.id,
    },
  }
}
