<template>
  <div class="flex p-3 items-center justify-between">
    <div class="text-sm min-w-0">
      <p class="truncate">
        <span class="font-bold">
          {{ t('payments.labels.payee') }}
        </span>

        <router-link
          v-if="payee?.id"
          :to="{ name: 'show-users', params: { id: payee.id } }"
        >
          &nbsp;{{ userDisplayNameWithInstitution }}
        </router-link>

        <span v-else>
          &nbsp;{{ userDisplayNameWithInstitution }}
        </span>
      </p>

      <p>
        <span class="font-bold">
          {{ t('payments.labels.payment_service') }}
        </span>

        {{ t(`enums.PaymentServiceEnum.${resource.attributes.payment_service}`) }}
      </p>
    </div>

    <div class="ml-4 text-xs font-bold flex-1">
      {{ amount }}
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { getAmountWithCurrency } from '@shared/helpers/payment'
import useUser from '@shared/hooks/resources/user'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

const payee = computed(() => (
  props.resource.relationships.payee ?? {}
))

const {
  displayNameWithInstitution: userDisplayNameWithInstitution,
} = useUser({ resource: payee.value })

const amount = computed(() => (
  getAmountWithCurrency(
    props.resource.attributes.net_amount,
    props.resource.attributes.currency,
  )
))
</script>
